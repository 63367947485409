var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"dataTableElement",class:'elevation-1 my-3 odd-background-lines ' + ('table-' + _vm.view),style:(("--col-offset-width: " + _vm.columnsOffsetWidth + "px;")),attrs:{"loading":_vm.isLoading,"headers":_vm.getHeaders,"items":_vm.items,"hide-default-footer":"","disable-pagination":"","fixed-header":"","height":"80vh","loading-text":"","no-data-text":"","disable-sort":""},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('dialog-training-officer',{attrs:{"commentary":_vm.currentCommentary,"is-wysiwyg":_vm.isCurrentCommentaryWYSIWYG,"is-loading":_vm.isCommentaryDialogLoading},on:{"close":_vm.closeCommentaryDialog,"save":_vm.handleSaveCommentary},model:{value:(_vm.isCommentaryDialogVisible),callback:function ($$v) {_vm.isCommentaryDialogVisible=$$v},expression:"isCommentaryDialogVisible"}})]},proxy:true},{key:"item.initials",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(item.trainingOfficer.user.initials)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEditLogisticSession)?_c('router-link',{attrs:{"to":{
        name: 'LogisticSession Edit',
        params: { idLogisticSession: item.id.toString() },
      }}},[_c('v-avatar',{staticClass:"rounded-lg",attrs:{"tile":"","color":"success"},on:{"click":_vm.saveScrollYPosition}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1):_vm._e()]}},{key:"item.initialsMainTrainingOfficer",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(item.mainTrainingOfficer.user.initials)+" ")])]}},{key:"header.initialsMainTrainingOfficer",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-select-user-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.training_officer'),"items":_vm.listTrainingOfficers,"loading":_vm.isLoadingTrainingOfficers,"value":_vm.filtersHeaders.mainTrainingOfficer},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {mainTrainingOfficer: $event}))}}})],1)]}},{key:"header.status",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-select-filter',{attrs:{"multiple":"","translation":_vm.$t('training_officers.list.filters.status'),"items":_vm.$appConfig.itemsStatusLogisticSession,"item-text":function (item) { return item.text; },"item-value":function (item) { return item.value; }},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingOfficerLogisticSessionDetails.status': $event}))}},model:{value:(_vm.filtersHeaders['trainingOfficerLogisticSessionDetails.status']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingOfficerLogisticSessionDetails.status', $$v)},expression:"filtersHeaders['trainingOfficerLogisticSessionDetails.status']"}})],1)]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('select-status-logistic-session',{attrs:{"label":_vm.$t('training_officers.list.status'),"hide-details":"","outlined":""},on:{"change":function($event){return _vm.updateTrainingOfficerLogisticSessionDetailProperty(item.trainingOfficerLogisticSessionDetail.id, 'status', $event)}},model:{value:(item.trainingOfficerLogisticSessionDetail.status),callback:function ($$v) {_vm.$set(item.trainingOfficerLogisticSessionDetail, "status", $$v)},expression:"item.trainingOfficerLogisticSessionDetail.status"}})]}},{key:"header.statusCircuit",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-select-filter',{attrs:{"multiple":"","translation":_vm.$t('training_officers.list.filters.status'),"items":_vm.$appConfig.itemsStatusLogisticSessionCircuit,"item-text":function (item) { return item.text; },"item-value":function (item) { return item.value; }},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {statusCircuit: $event}))}},model:{value:(_vm.filtersHeaders.statusCircuit),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "statusCircuit", $$v)},expression:"filtersHeaders.statusCircuit"}})],1)]}},{key:"item.statusCircuit",fn:function(ref){
      var item = ref.item;
return [_c('select-status-logistic-session-circuit',{attrs:{"label":_vm.$t('training_officers.list.status_circuit'),"hide-details":"","outlined":""},on:{"change":function($event){return _vm.updateLogisticSessionProperty(item.id, 'statusCircuit', $event)}},model:{value:(item.statusCircuit),callback:function ($$v) {_vm.$set(item, "statusCircuit", $$v)},expression:"item.statusCircuit"}})]}},{key:"header.statusSupplierRelationship",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-select-filter',{attrs:{"multiple":"","translation":_vm.$t('training_officers.list.filters.status'),"items":_vm.$appConfig.itemsStatusLogisticSessionSupplierRelationship,"item-text":function (item) { return item.text; },"item-value":function (item) { return item.value; }},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {statusSupplierRelationship: $event}))}},model:{value:(_vm.filtersHeaders.statusSupplierRelationship),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "statusSupplierRelationship", $$v)},expression:"filtersHeaders.statusSupplierRelationship"}})],1)]}},{key:"item.statusSupplierRelationship",fn:function(ref){
      var item = ref.item;
return [_c('select-status-logistic-session-supplier-relationship',{attrs:{"label":_vm.$t('training_officers.list.status_supplier_relationship'),"hide-details":"","outlined":""},on:{"change":function($event){return _vm.updateLogisticSessionProperty(item.id, 'statusSupplierRelationship', $event)}},model:{value:(item.statusSupplierRelationship),callback:function ($$v) {_vm.$set(item, "statusSupplierRelationship", $$v)},expression:"item.statusSupplierRelationship"}})]}},{key:"item.startAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startAt))+" ")]}},{key:"header.startAt",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date'),"min":_vm.monthSelected,"max":_vm.useDate().getLastMonthDayOfDate(_vm.monthSelected),"date-to-show":_vm.monthSelected,"range":""},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {periodDays: $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {periodDays: null}))}},model:{value:(_vm.filtersHeaders.periodDays),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "periodDays", $$v)},expression:"filtersHeaders.periodDays"}})]}},{key:"item.commentary",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"commentary-content cursor-pointer",domProps:{"innerHTML":_vm._s(item.commentary.commentary)},on:{"click":function($event){return _vm.handleEditCommentaryTrainingOfficer(item, 'commentary',null)}}})]}},{key:"item.infoAddressHousing",fn:function(ref){
      var item = ref.item;
return [(item.nameHousing)?_c('div',[_vm._v(" "+_vm._s(item.nameHousing)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.addressHousing)+" "+_vm._s(item.cityHousing)+" "+_vm._s(item.zipCodeHousing)+" ")]}},{key:"header.infoAddressHousing",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.address')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {infoAddressHousing: $event}))}},model:{value:(_vm.filtersHeaders.infoAddressHousing),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "infoAddressHousing", $$v)},expression:"filtersHeaders.infoAddressHousing"}})],1)]}},{key:"item.commentaryHousing",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"commentary-content text-pre-line cursor-pointer",on:{"click":function($event){return _vm.handleEditCommentaryProperty(item,'commentaryHousing', item.commentaryHousing, false)}}},[_vm._v(" "+_vm._s(item.commentaryHousing)+" ")])]}},{key:"header.commentaryHousing",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentaryHousing: $event}))}},model:{value:(_vm.filtersHeaders.commentaryHousing),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentaryHousing", $$v)},expression:"filtersHeaders.commentaryHousing"}})],1)]}},{key:"item.caterer",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"commentary-content text-pre-line cursor-pointer",on:{"click":function($event){return _vm.handleEditCommentaryProperty(item,'caterer', item.caterer, false)}}},[_vm._v(" "+_vm._s(item.caterer)+" ")])]}},{key:"header.caterer",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {caterer: $event}))}},model:{value:(_vm.filtersHeaders.caterer),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "caterer", $$v)},expression:"filtersHeaders.caterer"}})]}},{key:"item.commentaryCaterer",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"commentary-content text-pre-line cursor-pointer",domProps:{"innerHTML":_vm._s(item.commentaryCaterer)},on:{"click":function($event){return _vm.handleEditCommentaryProperty(item,'commentaryCaterer', item.commentaryCaterer, true)}}})]}},{key:"header.commentaryCaterer",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentaryCaterer: $event}))}},model:{value:(_vm.filtersHeaders.commentaryCaterer),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentaryCaterer", $$v)},expression:"filtersHeaders.commentaryCaterer"}})]}},{key:"item.commentaryTrainingLocation",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"commentary-content text-pre-line cursor-pointer",domProps:{"innerHTML":_vm._s(item.commentaryTrainingLocation)},on:{"click":function($event){return _vm.handleEditCommentaryProperty(item,'commentaryTrainingLocation', item.commentaryTrainingLocation, true)}}})]}},{key:"header.commentaryTrainingLocation",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentaryTrainingLocation: $event}))}},model:{value:(_vm.filtersHeaders.commentaryTrainingLocation),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentaryTrainingLocation", $$v)},expression:"filtersHeaders.commentaryTrainingLocation"}})]}},{key:"item.commentarySupplierRelationshipOfficer",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"commentary-content text-pre-line cursor-pointer",domProps:{"innerHTML":_vm._s(item.commentarySupplierRelationshipOfficer)},on:{"click":function($event){return _vm.handleEditCommentaryProperty(item,'commentarySupplierRelationshipOfficer', item.commentarySupplierRelationshipOfficer, true)}}})]}},{key:"header.commentarySupplierRelationshipOfficer",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentarySupplierRelationshipOfficer: $event}))}},model:{value:(_vm.filtersHeaders.commentarySupplierRelationshipOfficer),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentarySupplierRelationshipOfficer", $$v)},expression:"filtersHeaders.commentarySupplierRelationshipOfficer"}})]}},{key:"item.vehicleClient",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div'):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining,staticClass:"height-client-trainee"},[_vm._v(" "+_vm._s(_vm.getVehicleClient(needTraining))+" ")])}),0)])})}},{key:"item.report",fn:function(ref){
      var item = ref.item;
return [(_vm.view === _vm.TrainingOfficerView.OTHERS)?_vm._l((item.reports),function(report,indexReport){return _c('div',{key:indexReport,staticClass:"py-2 separated-items"},[_c('div',{staticClass:"commentary-content cursor-pointer",domProps:{"innerHTML":_vm._s(report.commentary)},on:{"click":function($event){return _vm.handleEditCommentaryTrainingOfficer(item, "reports", indexReport)}}})])}):_vm._e(),(_vm.view === _vm.TrainingOfficerView.DRIVER_PROGRAM)?[_c('div',{staticClass:"commentary-content cursor-pointer",domProps:{"innerHTML":_vm._s(item.reports[0].commentary)},on:{"click":function($event){return _vm.handleEditCommentaryTrainingOfficer(item, "reports", 0)}}})]:_vm._e()]}},{key:"item.eLearning",fn:function(ref){
      var item = ref.item;
return [(_vm.view === _vm.TrainingOfficerView.OTHERS)?_vm._l((item.eLearnings),function(eLearning,indexELearning){return _c('div',{key:indexELearning,staticClass:"py-2 separated-items"},[_c('div',{staticClass:"commentary-content cursor-pointer",domProps:{"innerHTML":_vm._s(eLearning.commentary)},on:{"click":function($event){return _vm.handleEditCommentaryTrainingOfficer(item, "eLearnings", indexELearning)}}})])}):_vm._e(),(_vm.view === _vm.TrainingOfficerView.DRIVER_PROGRAM)?[_c('div',{staticClass:"commentary-content cursor-pointer",domProps:{"innerHTML":_vm._s(item.eLearnings[0].commentary)},on:{"click":function($event){return _vm.handleEditCommentaryTrainingOfficer(item, "eLearnings", 0)}}})]:_vm._e()]}},{key:"item.billing",fn:function(ref){
      var item = ref.item;
return [(_vm.view === _vm.TrainingOfficerView.OTHERS)?_vm._l((item.billings),function(billing,indexBilling){return _c('div',{key:indexBilling,staticClass:"py-2 separated-items"},[_c('div',{staticClass:"commentary-content cursor-pointer",domProps:{"innerHTML":_vm._s(billing.commentary)},on:{"click":function($event){return _vm.handleEditCommentaryTrainingOfficer(item, "billings", indexBilling)}}})])}):_vm._e(),(_vm.view === _vm.TrainingOfficerView.DRIVER_PROGRAM)?[_c('div',{staticClass:"commentary-content cursor-pointer",domProps:{"innerHTML":_vm._s(item.billings[0].commentary)},on:{"click":function($event){return _vm.handleEditCommentaryTrainingOfficer(item, "billings", 0)}}})]:_vm._e()]}},{key:"item.city",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.trainingLocation.city)+" ")]}},{key:"header.city",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.city')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingLocation.city': $event}))}},model:{value:(_vm.filtersHeaders['trainingLocation.city']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingLocation.city', $$v)},expression:"filtersHeaders['trainingLocation.city']"}})]}},{key:"header.trainingLocation.type",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-select-filter',{attrs:{"multiple":"","translation":_vm.$t('training_officers.list.filters.training_location_type'),"items":_vm.$appConfig.trainingLocationTypes},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingLocation.type': $event}))}},model:{value:(_vm.filtersHeaders['trainingLocation.type']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingLocation.type', $$v)},expression:"filtersHeaders['trainingLocation.type']"}})]}},{key:"item.trainingLocation",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items align-start"},[_c('div',[_vm._v(" "+_vm._s(trainingSession.trainingLocation.name)+" ")]),_c('div',[_vm._v(" "+_vm._s(trainingSession.trainingLocation.address)+" "+_vm._s(trainingSession.trainingLocation.zipCode)+" "+_vm._s(trainingSession.trainingLocation.city)+" ")])])})}},{key:"header.trainingLocation",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.training_location')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.trainingLocation.text': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.trainingLocation.text']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.trainingLocation.text', $$v)},expression:"filtersHeaders['trainingSessions.trainingLocation.text']"}})]}},{key:"item.trainers",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainers),function(trainer,indexTrainer){return _c('v-chip',{key:indexTrainer,staticClass:"ma-1",attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(trainer.lastName)+" "+_vm._s(trainer.firstName)+" ")])})}},{key:"header.trainers",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.name')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainers.name': $event}))}},model:{value:(_vm.filtersHeaders['trainers.name']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainers.name', $$v)},expression:"filtersHeaders['trainers.name']"}})]}},{key:"item.schedules",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?[_vm._v(" "+_vm._s(_vm._f("formatHour")(trainingSession.startAt))+" - "+_vm._s(_vm._f("formatHour")(trainingSession.endAt))+" ")]:_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining,staticClass:"height-client-trainee"},[_vm._v(" "+_vm._s(_vm._f("formatHour")(trainingSession.startAt))+" - "+_vm._s(_vm._f("formatHour")(trainingSession.endAt))+" ")])})],2)})}},{key:"item.needTrainingsNames",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div'):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining,staticClass:"height-client-trainee"},[_vm._v(" "+_vm._s(needTraining.trainee.lastName)+" "+_vm._s(needTraining.trainee.firstName)+" ")])}),0)])})}},{key:"header.needTrainingsNames",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.name')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.trainee.name': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.trainee.name']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.trainee.name', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.trainee.name']"}})]}},{key:"item.needTrainingsStatus",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div'):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining,staticClass:"height-client-trainee"},[(needTraining.status.code === _vm.NeedTrainingStatus.FORME || needTraining.status.code === _vm.NeedTrainingStatus.ABSENT)?_c('span',[_vm._v(" "+_vm._s(needTraining.status.status)+" ")]):_vm._e()])}),0)])})}},{key:"header.needTrainingsStatus",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-select-filter',{attrs:{"items":_vm.$appConfig.needTrainingStatusFormeAndAbsent,"item-text":function (item) { return item.label; },"item-value":function (item) { return item.code; },"translation":_vm.$t('training_officers.list.filters.status')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.status.code': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.status.code']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.status.code', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.status.code']"}})]}},{key:"item.needTrainingsCompanies",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div'):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining,staticClass:"height-client-trainee"},[(needTraining.company)?_c('span',[_vm._v(_vm._s(needTraining.company.name))]):_vm._e()])}),0)])})}},{key:"header.needTrainingsCompanies",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.company')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.company.name': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.company.name']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.company.name', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.company.name']"}})]}},{key:"item.needTrainingsPhones",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div'):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining,staticClass:"height-client-trainee"},[_vm._v(" "+_vm._s(needTraining.trainee.phoneNumber)+" "+_vm._s(needTraining.trainee.phoneNumber && needTraining.trainee.mobilePhone ? ' / ' : '')+" "+_vm._s(needTraining.trainee.mobilePhone)+" ")])}),0)])})}},{key:"header.needTrainingsPhones",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.phone')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.trainee.phones': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.trainee.phones']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.trainee.phones', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.trainee.phones']"}})]}},{key:"item.needTrainingsEmails",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div'):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining,staticClass:"height-client-trainee"},[_vm._v(" "+_vm._s(needTraining.trainee.email)+" ")])}),0)])})}},{key:"header.needTrainingsEmails",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.email')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.trainee.email': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.trainee.email']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.trainee.email', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.trainee.email']"}})],1)]}},{key:"item.client",fn:function(ref){
      var item = ref.item;
return _vm._l((item.companies),function(company,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(company.name)+" ")])})}},{key:"header.client",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.company')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.company.name': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.company.name']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.company.name', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.company.name']"}})]}},{key:"item.client2",fn:function(ref){
      var item = ref.item;
return _vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[_vm._v(" "+_vm._s(company.name)+" ")])})}},{key:"header.client2",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.company')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.company.name': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.company.name']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.company.name', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.company.name']"}})]}},{key:"item.clientWithInitialsTrainingOfficer",fn:function(ref){
      var item = ref.item;
return _vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items flex-row justify-space-between"},[_c('div',[_vm._v(_vm._s(company.name))]),_c('v-avatar',{staticClass:"text-caption mr-2",attrs:{"size":"30","color":"secondary"}},[_vm._v(" "+_vm._s(_vm.getInitialsAddedBy(item, company))+" ")])],1)})}},{key:"header.clientWithInitialsTrainingOfficer",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.company')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.company.name': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.company.name']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.company.name', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.company.name']"}})]}},{key:"item.trainingType",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingTypes),function(trainingType,index){return _c('v-chip',{key:index,attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(trainingType)+" ")])})}},{key:"header.trainingType",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-select-filter',{attrs:{"multiple":"","translation":_vm.$t('training_officers.list.filters.training_type'),"items":_vm.trainingTypesList,"item-text":function (item) { return item.name; },"item-value":function (item) { return item.id; }},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.trainingType.id': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.trainingType.id']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.trainingType.id', $$v)},expression:"filtersHeaders['trainingSessions.trainingType.id']"}})],1)]}},{key:"item.nbPax",fn:function(ref){
      var item = ref.item;
return [_vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[_vm._v(" "+_vm._s(_vm.getNbPax(item.trainingSessions, company))+" ")])}),(item.companies.length === 0)?_c('div',{staticClass:"py-2 separated-items"},[_vm._v(" 0 ")]):_vm._e()]}},{key:"item.convention",fn:function(ref){
      var item = ref.item;
return _vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},_vm._l((item.trackingDates),function(trackingDate,indexTrackingDate){return _c('div',{key:indexTrackingDate},[(trackingDate.company.id === company.id)?[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto","disabled":_vm.isCompanyWithoutConvention(item, trackingDate.company)},on:{"validate":function($event){return _vm.updateTrackingDateProperty(trackingDate.id, 'sendConventionAt', $event)}},model:{value:(trackingDate.sendConventionAt),callback:function ($$v) {_vm.$set(trackingDate, "sendConventionAt", $$v)},expression:"trackingDate.sendConventionAt"}})]:_vm._e()],2)}),0)})}},{key:"header.convention",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendConventionAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendConventionAt': null}))}},model:{value:(_vm.filtersHeaders['trackingDateLogisticSessions.sendConventionAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trackingDateLogisticSessions.sendConventionAt', $$v)},expression:"filtersHeaders['trackingDateLogisticSessions.sendConventionAt']"}})],1)]}},{key:"item.convocation",fn:function(ref){
      var item = ref.item;
return [(_vm.view === _vm.TrainingOfficerView.OTHERS)?_vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},_vm._l((item.trackingDates),function(trackingDate,indexTrackingDate){return _c('div',{key:indexTrackingDate},[(trackingDate.company.id === company.id)?[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto","disabled":_vm.isCompanyWithoutConvocation(item, trackingDate.company)},on:{"validate":function($event){return _vm.updateTrackingDateProperty(trackingDate.id, 'sendConvocationAt', $event)}},model:{value:(trackingDate.sendConvocationAt),callback:function ($$v) {_vm.$set(trackingDate, "sendConvocationAt", $$v)},expression:"trackingDate.sendConvocationAt"}})]:_vm._e()],2)}),0)}):_vm._e(),(_vm.view === _vm.TrainingOfficerView.DRIVER_PROGRAM)?[_vm._l((item.trackingDates),function(trackingDate,indexTrackingDate){return [(trackingDate.trainingOfficer !== null)?[_c('date-field',{key:indexTrackingDate,attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto"},on:{"validate":function($event){return _vm.updateTrackingDateProperty(trackingDate.id, 'sendConvocationAt', $event)}},model:{value:(trackingDate.sendConvocationAt),callback:function ($$v) {_vm.$set(trackingDate, "sendConvocationAt", $$v)},expression:"trackingDate.sendConvocationAt"}})]:_vm._e()]})]:_vm._e()]}},{key:"header.convocation",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendConvocationAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendConvocationAt': null}))}},model:{value:(_vm.filtersHeaders['trackingDateLogisticSessions.sendConvocationAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trackingDateLogisticSessions.sendConvocationAt', $$v)},expression:"filtersHeaders['trackingDateLogisticSessions.sendConvocationAt']"}})],1)]}},{key:"item.folderVehicle",fn:function(ref){
      var item = ref.item;
return [_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto"},on:{"validate":function($event){return _vm.updateTrainingOfficerLogisticSessionDetailProperty(item.trainingOfficerLogisticSessionDetail.id, 'sendVehicleFolderAt', item.trainingOfficerLogisticSessionDetail.sendVehicleFolderAt)}},model:{value:(item.trainingOfficerLogisticSessionDetail.sendVehicleFolderAt),callback:function ($$v) {_vm.$set(item.trainingOfficerLogisticSessionDetail, "sendVehicleFolderAt", $$v)},expression:"item.trainingOfficerLogisticSessionDetail.sendVehicleFolderAt"}})]}},{key:"header.folderVehicle",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingOfficerLogisticSessionDetails.sendVehicleFolderAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingOfficerLogisticSessionDetails.sendVehicleFolderAt': null}))}},model:{value:(_vm.filtersHeaders['trainingOfficerLogisticSessionDetails.sendVehicleFolderAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingOfficerLogisticSessionDetails.sendVehicleFolderAt', $$v)},expression:"filtersHeaders['trainingOfficerLogisticSessionDetails.sendVehicleFolderAt']"}})],1)]}},{key:"item.smsAndMail",fn:function(ref){
      var item = ref.item;
return [(_vm.view === _vm.TrainingOfficerView.OTHERS)?_vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},_vm._l((item.trackingDates),function(trackingDate,indexTrackingDate){return _c('div',{key:indexTrackingDate},[(trackingDate.company.id === company.id)?[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto"},on:{"validate":function($event){return _vm.updateTrackingDateProperty(trackingDate.id, 'sendSmsAndMailCLientAt', $event)}},model:{value:(trackingDate.sendSmsAndMailCLientAt),callback:function ($$v) {_vm.$set(trackingDate, "sendSmsAndMailCLientAt", $$v)},expression:"trackingDate.sendSmsAndMailCLientAt"}})]:_vm._e()],2)}),0)}):_vm._e(),(_vm.view === _vm.TrainingOfficerView.DRIVER_PROGRAM)?[_vm._l((item.trackingDates),function(trackingDate,indexTrackingDate){return [(trackingDate.trainingOfficer !== null)?[_c('date-field',{key:indexTrackingDate,attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto"},on:{"validate":function($event){return _vm.updateTrackingDateProperty(trackingDate.id, 'sendSmsAndMailCLientAt', $event)}},model:{value:(trackingDate.sendSmsAndMailCLientAt),callback:function ($$v) {_vm.$set(trackingDate, "sendSmsAndMailCLientAt", $$v)},expression:"trackingDate.sendSmsAndMailCLientAt"}})]:_vm._e()]})]:_vm._e()]}},{key:"header.smsAndMail",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendSmsAndMailCLientAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendSmsAndMailCLientAt': null}))}},model:{value:(_vm.filtersHeaders['trackingDateLogisticSessions.sendSmsAndMailCLientAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trackingDateLogisticSessions.sendSmsAndMailCLientAt', $$v)},expression:"filtersHeaders['trackingDateLogisticSessions.sendSmsAndMailCLientAt']"}})],1)]}},{key:"item.mailConcession",fn:function(ref){
      var item = ref.item;
return [(_vm.view === _vm.TrainingOfficerView.OTHERS)?_vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},_vm._l((item.trackingDates),function(trackingDate,indexTrackingDate){return _c('div',{key:indexTrackingDate},[(trackingDate.company.id === company.id)?[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto"},on:{"validate":function($event){return _vm.updateTrackingDateProperty(trackingDate.id, 'sendMailCarDealerAt', $event)}},model:{value:(trackingDate.sendMailCarDealerAt),callback:function ($$v) {_vm.$set(trackingDate, "sendMailCarDealerAt", $$v)},expression:"trackingDate.sendMailCarDealerAt"}})]:_vm._e()],2)}),0)}):_vm._e(),(_vm.view === _vm.TrainingOfficerView.DRIVER_PROGRAM)?[_vm._l((item.trackingDates),function(trackingDate,indexTrackingDate){return [(trackingDate.trainingOfficer === null)?[_c('date-field',{key:indexTrackingDate,attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto"},on:{"validate":function($event){return _vm.updateTrackingDateProperty(trackingDate.id, 'sendMailCarDealerAt', $event)}},model:{value:(trackingDate.sendMailCarDealerAt),callback:function ($$v) {_vm.$set(trackingDate, "sendMailCarDealerAt", $$v)},expression:"trackingDate.sendMailCarDealerAt"}})]:_vm._e()]})]:_vm._e()]}},{key:"header.mailConcession",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendMailCarDealerAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendMailCarDealerAt': null}))}},model:{value:(_vm.filtersHeaders['trackingDateLogisticSessions.sendMailCarDealerAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trackingDateLogisticSessions.sendMailCarDealerAt', $$v)},expression:"filtersHeaders['trackingDateLogisticSessions.sendMailCarDealerAt']"}})],1)]}},{key:"item.smsClient",fn:function(ref){
      var item = ref.item;
return [(_vm.view === _vm.TrainingOfficerView.OTHERS)?_vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},_vm._l((item.trackingDates),function(trackingDate,indexTrackingDate){return _c('div',{key:indexTrackingDate},[(trackingDate.company.id === company.id)?[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto"},on:{"validate":function($event){return _vm.updateTrackingDateProperty(trackingDate.id, 'sendSmsAt', $event)}},model:{value:(trackingDate.sendSmsAt),callback:function ($$v) {_vm.$set(trackingDate, "sendSmsAt", $$v)},expression:"trackingDate.sendSmsAt"}})]:_vm._e()],2)}),0)}):_vm._e(),(_vm.view === _vm.TrainingOfficerView.DRIVER_PROGRAM)?[_vm._l((item.trackingDates),function(trackingDate,indexTrackingDate){return [(trackingDate.trainingOfficer !== null)?[_c('date-field',{key:indexTrackingDate,attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto"},on:{"validate":function($event){return _vm.updateTrackingDateProperty(trackingDate.id, 'sendSmsAt', $event)}},model:{value:(trackingDate.sendSmsAt),callback:function ($$v) {_vm.$set(trackingDate, "sendSmsAt", $$v)},expression:"trackingDate.sendSmsAt"}})]:_vm._e()]})]:_vm._e()]}},{key:"header.smsClient",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendSmsAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendSmsAt': null}))}},model:{value:(_vm.filtersHeaders['trackingDateLogisticSessions.sendSmsAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trackingDateLogisticSessions.sendSmsAt', $$v)},expression:"filtersHeaders['trackingDateLogisticSessions.sendSmsAt']"}})],1)]}},{key:"item.folderPostTrainingSession",fn:function(ref){
      var item = ref.item;
return [(_vm.view === _vm.TrainingOfficerView.OTHERS)?_vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},_vm._l((item.trackingDates),function(trackingDate,indexTrackingDate){return _c('div',{key:indexTrackingDate},[(trackingDate.company.id === company.id)?[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto"},on:{"validate":function($event){return _vm.updateTrackingDateProperty(trackingDate.id, 'sendPostTrainingDocumentsAt', $event)}},model:{value:(trackingDate.sendPostTrainingDocumentsAt),callback:function ($$v) {_vm.$set(trackingDate, "sendPostTrainingDocumentsAt", $$v)},expression:"trackingDate.sendPostTrainingDocumentsAt"}})]:_vm._e()],2)}),0)}):_vm._e(),(_vm.view === _vm.TrainingOfficerView.DRIVER_PROGRAM)?[_vm._l((item.trackingDates),function(trackingDate,indexTrackingDate){return [(trackingDate.trainingOfficer !== null)?[_c('date-field',{key:indexTrackingDate,attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto"},on:{"validate":function($event){return _vm.updateTrackingDateProperty(trackingDate.id, 'sendPostTrainingDocumentsAt', $event)}},model:{value:(trackingDate.sendPostTrainingDocumentsAt),callback:function ($$v) {_vm.$set(trackingDate, "sendPostTrainingDocumentsAt", $$v)},expression:"trackingDate.sendPostTrainingDocumentsAt"}})]:_vm._e()]})]:_vm._e()]}},{key:"header.folderPostTrainingSession",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendPostTrainingDocumentsAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendPostTrainingDocumentsAt': null}))}},model:{value:(_vm.filtersHeaders['trackingDateLogisticSessions.sendPostTrainingDocumentsAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trackingDateLogisticSessions.sendPostTrainingDocumentsAt', $$v)},expression:"filtersHeaders['trackingDateLogisticSessions.sendPostTrainingDocumentsAt']"}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }