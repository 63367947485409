















































import { defineComponent } from '@vue/composition-api'
import { VueEditor } from 'vue2-editor'

export default defineComponent({
  name: 'dialogTrainingOfficer',
  components: { VueEditor },
  props: {
    commentary: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      required: true,
    },
    isWysiwyg: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      editingCommentary: '',
    }
  },
  watch: {
    value (newValue) {
      if (newValue) {
        this.editingCommentary = this.commentary as string
      } else {
        this.editingCommentary = ''
      }
    },
  },
  methods: {
    closeCommentaryDialog () {
      this.$emit('close')
    },
    handleSaveCommentary () {
      this.$emit('save', this.editingCommentary)
    },
  },
})
